import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import Philosophy from '../images/glc-suv/highlights/philosophy.jpeg'
import Paints from '../images/glc-suv/highlights/paints.jpeg'
import Upholstery from '../images/glc-suv/highlights/upholstery.jpeg'

import HeaderImage from '../images/glc-suv/header-glc-suv.jpeg'

import InterieurImage from '../images/glc-suv/gallery/interieur-tesaser-glc-suv.jpeg'
import ExterieurImage from '../images/glc-suv/gallery/exterieur-tesaser-glc-suv.jpeg'

import InterieurImageGallery1 from '../images/glc-suv/gallery/gallery1-interieur-glc-suv.jpeg'
import InterieurImageGallery2 from '../images/glc-suv/gallery/gallery2-interieur-glc-suv.jpeg'
import InterieurImageGallery3 from '../images/glc-suv/gallery/gallery3-interieur-glc-suv.jpeg'

import ExterieurImageGallery1 from '../images/glc-suv/gallery/gallery1-exterieur-glc-suv.jpeg'
import ExterieurImageGallery2 from '../images/glc-suv/gallery/gallery2-exterieur-glc-suv.jpeg'
import ExterieurImageGallery3 from '../images/glc-suv/gallery/gallery3-exterieur-glc-suv.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
]

const GLCSuv = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Auf jedem Gelände in seinem Element. Der GLC.</H1>
      <p>
        Unter allen Einsatzbedingungen überzeugt der GLC als Mid-Size SUV mit
        ausgezeichneter, markentypischer Sicherheit, modernsten
        Assistenzsystemen und Energieeffizienz. Die in diesem Segment
        einzigartige Mehrkammer-Luftfederung AIR BODY CONTROL, das erweiterte
        Fahrdynamikprogramm DYNAMIC SELECT und der permanente Allradantrieb
        4MATIC erhöhen sowohl den Fahrkomfort als auch die sportliche Agilität
        auf jedem Untergrund.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>Individualität in ihrer schönsten Form.</H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={Philosophy}
          title="designo Philosophie"
          headline="designo Philosophie"
          description="Exklusive Lackierungen – handgenähte Lederausstattungen: Mit designo wird jede Fahrt in Ihrem GLC zu einem sinnlichen Erlebnis. Ausgewählte Materialien werden in traditioneller Handwerkskunst aufwendig verarbeitet. So findet Individualität zu ihrer schönsten Form."
        />
        <ImageBox
          imagesrc={Paints}
          title="designo Lacke"
          headline="designo Lacke"
          description="Die Lackierung designo diamantweiß bright verleiht dem GLC besondere Eleganz und Wertigkeit. Für den edlen Schimmer wird die farbgebende Schicht mit einer Perlglanzeffekt­Schicht kombiniert. Vollendet wird die Technik mit einer dritten Schicht aus Klarlack. designo diamantweiß bright harmoniert perfekt mit dem feinen designo Interieur zweifarbig platinweiß pearl/schwarz. Eine von vielen Farb­- und Materialkombinationen, die den GLC zum persönlichsten SUV seiner Klasse machen."
        />
        <ImageBox
          imagesrc={Upholstery}
          title="designo Polsterungen"
          headline="designo Polsterungen"
          description="Das designo Interieur des GLC ist in Leder Nappa platinweiß pearl/schwarz oder Leder Nappa schwarz aufwendig gestaltet. Ein exklusives Rautendesign und wiederkehrende Kontrastziernähte setzen edle Akzente. Wie aus einem Guss wirken die harmonisch abgestimmten designo Interieurs. Von den Polstern über die Türmittelfelder zu den Instrumententafeloberteilen wird der Innenraum optisch und haptisch zu einem emotionalen Erlebnis. Ob das Farbkonzept progressive Sportlichkeit oder stilvollen Luxus widerspiegelt, entscheiden Sie."
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default GLCSuv
